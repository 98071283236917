import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from "react-router-dom";
import {getHistoryCard, getSharedCard} from "../../api/cards";
import Loader from "../../components/Loader/Loader";
import Card from "../../components/Card/Card";

const HistoryCardPage = () => {
    const { id } = useParams();
    const { state: locationState } = useLocation();
    const [card, setCard] = useState({});
    const [isCardLoaded, setCardIsLoaded] = useState(false);
    const [isReferralCard, setCardIsReferral] = useState(false);

    useEffect(() => {
        if (id) {
            if (locationState?.isReferralPrediction) {
                setCardIsReferral(true);

                getSharedCard(id)
                    .then(response => {
                        setCard(response.journal);
                    })
                    .catch(e => console.log(e))
                    .finally(() => setCardIsLoaded(true));
            }

            getHistoryCard(id)
                .then(response => {
                    setCard(response.journal);
                })
                .catch(e => console.log(e))
                .finally(() => setCardIsLoaded(true));
        }

    }, [id]);

    return (
        <div className="historyCardPage page">
            {!isCardLoaded ?
                <Loader/>
                :
                <Card
                    card={card.card}
                    journalId={card.id}
                    parent={card.parent}
                    children={card.children}
                    queryText={card.questions}
                    inviteLink={card.invite_link}
                    isReferral={isReferralCard}
                    isInJournalPage={true}
                />
            }
        </div>
    );
};

export default HistoryCardPage;