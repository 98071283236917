import React, {useEffect, useState} from 'react';
import './Card.scss';
import {useTranslation} from "react-i18next";
import {useTelegram} from "../../hooks/useTelegram";
import {getUserData, login} from "../../api/user";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {setUser} from "../../store/mainSlice";
import {cardMeening} from "../../enum/CardMeeningEnum";
import Tooltip from "../Tooltip/Tooltip";
import {buyPredictions, getHistoryCard} from "../../api/cards";
import Icon from "../Icon/Icon";
import Loader from "../Loader/Loader";
import HistoryList from "../HistoryList/HistoryList";

const Card = ({
    card,
    journalId,
    isFaceDown = false,
    isReferral = false,
    isInJournalPage = false,
    queryText,
    inviteLink,
    parent,
    children,
    onCardClick,
    onNewPredictionBtnClick
}) => {
    const {t, i18n} = useTranslation();
    const {tg, tgUser} = useTelegram();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(state => state.main.user);

    const [cardName, setCardName] = useState('');
    const [cardDescription, setCardDescription] = useState('');
    const [parentCard, setParentCard] = useState([]);
    const [parentCardIsLoaded, setParentCardIsLoaded] = useState(false);
    const [childrenCard, setChildrenCard] = useState([]);
    const [childrenCardIsLoaded, setChildrenCardIsLoaded] = useState(false);

    const shareCard = () => {
        const appLink = inviteLink ? inviteLink : 'https://t.me/tarotalks_bot/Tarotalks';
        const text = t('textForShare');

        gtag("event", isInJournalPage ? "share_application_journal" : "share_application_main");

        tg.openTelegramLink(`https://t.me/share/url?url=${appLink}&text=${text}`);
    }

    const onCardClickHandler = () => {
        if (isFaceDown) {
            onCardClick();
        }
    }

    const getCardName = () => {
        if (card?.name) {
            setCardName(card.name);
        }
    }

    const getCardDescription = () => {
        if (card?.i18n_explanation && card.i18n_explanation[i18n.language]) {
            setCardDescription(card.i18n_explanation[i18n.language]);
        } else if (card?.explanation) {
            setCardDescription(card.explanation);
        }
    }

    const updateUserData = () => {
        getUserData()
            .then(response => {
                dispatch(setUser(response));

                if (response.have_free_prediction) {
                    getInvoiceLinkHandler(true);
                }
            })
            .catch((e) => console.log(e));
    }

    const onSuccessPayment = (result) => {
        gtag("event", "successfull_payment");

        updateUserData();
        // login(tgUser?.id)
        //     .then((response) => {
        //         dispatch(setUser(response.user));
        //
        //         if (response.user?.have_free_prediction) {
        //             getInvoiceLinkHandler(true);
        //         }
        //     })
        //     .catch((e) => console.log(e));
    }

    const getInvoiceLinkHandler = (isForce = false, isMoreDetailBtn = false) => {
        if (user?.have_free_prediction || isForce) {
            gtag("event", isReferral ? "after_ref_prediction" : "new_prediction");

            if (location.pathname === '/') {
                onNewPredictionBtnClick(journalId);
            } else {
                const state = {shouldGetNewCard: true};

                if (isMoreDetailBtn) {
                    state.parentId = journalId;
                }

                navigate('/', {state});
            }
        } else {
            gtag("event", "start_payment");

            tg.showPopup({
                title: t('buyMorePredictions'),
                message: t('howManyPredictionsBuy'),
                buttons: [
                    {id: 'cancel', type: 'cancel'},
                    {id: 5, type: 'default', text: 5},
                    {id: 1, type: 'default', text: 1},
                ]
            }, (buttonId) => {
                if (buttonId !== 'cancel') {
                    buyPredictions(parseInt(buttonId))
                        .then(response => {
                            tg.openInvoice(response.result, onSuccessPayment);
                        })
                        .catch(e => console.log(e));
                }
            });

            // tg.showPopup({
            //     title: t('predictionsIsEnd'),
            //     message: t('buyNewPrediction'),
            //     buttons: [
            //         {id: 0, type: 'cancel'},
            //         {id: 1, type: 'default', text: t('buy')}
            //     ]
            // }, (buttonId) => {
            //     if (buttonId == 1) {
            //         getInvoiceLink()
            //             .then(response => {
            //                 tg.openInvoice(response.link, onSuccessPayment);
            //             })
            //             .catch(e => console.log(e));
            //     }
            // })
        }
    }

    const getMeening = () => {
        if (card) {
            if (card.new_meening == cardMeening.yes) {
                return {
                    subclass: ' yes',
                    text: t('yes')
                };
            }

            if (card.new_meening == cardMeening.no) {
                return {
                    subclass: ' no',
                    text: t('no')
                };
            }

            if (card.new_meening == cardMeening.maybe) {
                return {
                    subclass: ' maybe',
                    text: t('maybe')
                };
            }
        }

        return {
            subclass: '',
            text: ''
        };
    }

    const goToPrediction = (id) => {
        navigate('/history/' + id);
    }

    useEffect(() => {
        getCardName();
        getCardDescription();
    }, [card]);

    useEffect(() => {
        if (!!parent) {
            getHistoryCard(parent.id)
                .then(response => {
                    const {journal} = response;

                    setParentCard([journal]);
                })
                .catch(e => console.log(e))
                .finally(() => setParentCardIsLoaded(true));
        }

        if (!!children) {
            getHistoryCard(children.id)
                .then(response => {
                    const {journal} = response;

                    setChildrenCard([journal]);
                })
                .catch(e => console.log(e))
                .finally(() => setChildrenCardIsLoaded(true));
        }
    }, [parent, children]);

    return (
        <div className={
            'card'
            + (isFaceDown ? ' facedDown' : '')
            + (getMeening().subclass)}>
            {!isFaceDown && (cardName || cardDescription) &&
                <div className="card__textTop">
                    {cardName && <h1 className="card__name">{cardName}</h1>}

                    {cardDescription && <p className="card__description">{cardDescription}</p>}

                    {!isReferral &&
                        <button
                            className="card__shareBtn button"
                            onClick={shareCard}
                        ><Icon name={"share"} /></button>
                    }
                </div>
            }

            <div className="card__imgOuter">
                <div className="card__fog">
                    <div className="card__fogElement"/>
                    <div className="card__fogElement"/>
                    <div className="card__fogElement"/>
                    <div className="card__fogElement"/>
                    <div className="card__fogElement"/>
                </div>

                <div className="card__img">
                    <img
                        className="card__imgRays"
                        src="/images/rays.svg"
                        alt=""
                    />

                    <div
                        className="card__imgInner"
                        onClick={onCardClickHandler}
                    >
                        {isFaceDown ?
                            <img
                                className="card__back"
                                src="/images/cardBack_01.jpg"
                                alt=""
                            />
                            :
                            card?.image && <>
                                <img
                                    className="card__front"
                                    src={card.image}
                                    alt=""
                                />
                            </>
                        }
                    </div>
                </div>
            </div>

            {!isFaceDown &&
                <div className="card__textBottom">
                    {card?.new_meening &&
                        <div className="card__meening">
                            <span className="card__meeningText">
                                {getMeening().text}

                                <Tooltip>
                                    <span className="icon icon-question">?</span>

                                    <p>{t('meaningTooltip')}</p>
                                </Tooltip>
                            </span>
                        </div>
                    }

                    {queryText &&
                        <p className="card__query">{queryText}</p>
                    }

                    <div className="card__buttons">
                        {journalId && !children &&
                            <button
                                className={'card__moreDetailsBtn button' + ((typeof user?.count_predictions === 'number' && user?.count_predictions > 0) || user?.count_predictions === '♾️' ? ' withCount' : '')}
                                onClick={() => getInvoiceLinkHandler(false, true)}
                            >{t('getMoreDetails')}</button>
                        }

                        <button
                            className={'card__newPredictionBtn button' + ((typeof user?.count_predictions === 'number' && user?.count_predictions > 0) || user?.count_predictions === '♾️' ? ' withCount' : '')}
                            onClick={() => getInvoiceLinkHandler()}
                        >
                            {(typeof user?.count_predictions === 'number' && user?.count_predictions > 0) || user?.count_predictions === '♾️' ?
                                <>
                                    <span>{t(isReferral ? 'getYourPrediction' : 'newPrediction')}</span>

                                    {t('available')}: {user.count_predictions}
                                </>
                                :
                                t(isReferral ? 'getYourPrediction' : 'newPrediction')
                            }
                        </button>
                    </div>

                    {card?.ext_description &&
                        <div className="card__extendedDescription">
                            <p className="card__extendedDescriptionTitle">{t('moreDetails')}</p>

                            <p className="card__extendedDescriptionText">{card.ext_description}</p>
                        </div>
                    }

                    {(parent || children) &&
                        <div className="card__family">
                            {parent ? parentCardIsLoaded ?
                                <div
                                    className="cardFamily"
                                    onClick={() => goToPrediction(parent.id)}
                                >
                                    <p className="cardFamily__title">{t('clarificationFor')}</p>

                                    <HistoryList history={parentCard} />
                                </div>
                                :
                                <Loader />
                            : null}

                            {children ? childrenCardIsLoaded ?
                                <div
                                    className="cardFamily"
                                    onClick={() => goToPrediction(children.id)}
                                >
                                    <p className="cardFamily__title">{t('clarification')}</p>

                                    <HistoryList history={childrenCard} />
                                </div>
                                :
                                <Loader />
                            : null}
                        </div>
                    }
                </div>
            }
        </div>
    );
};

export default Card;