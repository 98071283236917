import React from 'react';

const Icon = ({name}) => {
    return (
        <>
            {name === 'share' &&
                <svg
                    className="icon"
                    version="1.1"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g transform="translate(0 -1036.4)">
                        <path transform="matrix(.84528 0 0 .84528 33.943 1042.2)" d="m-22.411-3.3304a2.3661 2.3661 0 1 1-4.7321 0 2.3661 2.3661 0 1 1 4.7321 0z"/>
                        <path transform="matrix(.84528 0 0 .84528 33.943 1052.2)" d="m-22.411-3.3304a2.3661 2.3661 0 1 1-4.7321 0 2.3661 2.3661 0 1 1 4.7321 0z"/>
                        <path transform="matrix(.84528 0 0 .84528 23.943 1047.2)" d="m-22.411-3.3304a2.3661 2.3661 0 1 1-4.7321 0 2.3661 2.3661 0 1 1 4.7321 0z"/>
                        <path transform="translate(0 1036.4)" d="m12.777 2.5527-10.895 5.4473 10.895 5.4473 0.44531-0.89453-9.1055-4.5527 9.1055-4.5527z"/>
                    </g>
                </svg>
            }
        </>
    );
};

export default Icon;