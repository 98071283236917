import {$authHost} from "./index";

export const getDailyCard = async (params) => {
    const {data} = await $authHost.post('daily_card', params);
    return data;
};

export const getHistory = async (params) => {
    const {data} = await $authHost.get('journal', {params});
    return data;
};

export const getHistoryCard = async (id) => {
    const {data} = await $authHost.get('journal/' + id);
    return data;
};

export const getSharedCard = async (id) => {
    const {data} = await $authHost.get('prediction/' + id);
    return data;
};

export const setHistoryCard = async (card_id, questions) => {
    const {data} = await $authHost.post('journal', {card_id, questions});
    return data;
};

export const buyPredictions = async (amount) => {
    const {data} = await $authHost.get('get_prediction/'+ amount);
    return data;
};

export const buyPremium = async () => {
    const {data} = await $authHost.get('unlimit');
    return data;
};