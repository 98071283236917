import React from 'react';
import {NavLink} from "react-router-dom";
import './HistoryList.scss';
import {cardMeening} from "../../enum/CardMeeningEnum";

const HistoryList = ({history}) => {
    const getMeeningClass = meening => {
        if (meening == cardMeening.yes) {
            return ' positive';
        }

        if (meening == cardMeening.no) {
            return ' negative';
        }

        if (meening == cardMeening.maybe) {
            return ' maybe';
        }

        return '';
    }

    return (
        <div className="historyList">
            {history && history.map((elem) =>
                <NavLink
                    key={elem.id}
                    to={'/history/' + elem.id}
                    className={`historyElement${getMeeningClass(elem.new_meening)}`}
                    title={elem.questions ? elem.questions : null}
                >
                    <span className="historyElement__text">
                        <span className="historyElement__date">{new Date(elem.createdat).toLocaleDateString('ru-RU')}</span>

                        {elem.questions && <span className="historyElement__question">{elem.questions}</span>}
                    </span>

                    <div className="historyElement__card">
                        <img
                            src={elem.image ? elem.image : '/images/cardBack_01.jpg'}
                            alt=""
                        />
                    </div>
                </NavLink>
            )}
        </div>
    );
};

export default HistoryList;